<template>
	<div class="grid">
		<div class="col-12">
			<div v-if="pageLoading">
				<div class="custom-skeleton">
					<div class="card">
                        <div class="flex mb-3">
                            <Skeleton height="6rem" class="mb-1"></Skeleton>
                        </div>
                        <Skeleton width="100%" height="4rem" class="my-1"></Skeleton>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
						</div>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
						</div>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
						</div>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
						</div>
                    </div>
				</div>
			</div>
			
			<div v-else>
				<Toast/>

				<div class="surface-card p-4 shadow-2 border-round mb-1" v-if="agencyData != ''">
					<div class="grid">
						<div class="col-12 lg:col-2">
							<div class="relative content text-center">
								<img v-if="agencyData.logo" class="w-full border-round" :src="constant.imgUrl + agencyData.logo" alt="logo" role="presentation"/>
								<img v-else src="../assets/logo-light-128.png" alt="logo"/>
								<div class="absolute top-0 left-0 w-full hov-button">
									<input type="file" id="upload" accept="image/*" hidden @change="imageSelected"/>
									<label for="upload"><span class="p-button p-button-raised w-full lign-items-center justify-content-center">Change Logo</span></label>
								</div>
							</div>
						</div>
						<div class="col-12 lg:col-6 text-center">
							<div class="font-medium text-6xl text-900 text-cyan-900 mb-3">{{agencyData.name}}</div>
							<div class="text-cyan-500 lg:mb-5" style="font-family: Copperplate, Papyrus, fantasy;">Agency id: #00{{agencyData.id}} and Retting: {{agencyData.rating.average}}</div>
						</div>
						<div class="col-12 lg:col-4 text-center">
							<div v-if="user.role=='owner'" class="flex flex-grow-1 align-items-center lg:justify-content-end justify-content-center">
								<Button label="Update Basic" icon="pi pi-plus" class="p-button p-button-success mr-2" @click="editOpen(agencyData)" />
								<Button label="Update Address" icon="pi pi-plus" class="p-button p-button-info" @click="editAddress(agencyData)" />
							</div>
						</div>
					</div>
					<div class="grid grid-nogutter border-top-1 surface-border pt-2">
						<div class="col-12 md:col-6 p-3">
							<div class="text-cyan-900 font-medium mb-2">Owner</div>
							<div class="text-900">{{agencyData.owner.name}}</div>
						</div>
						<div class="col-12 md:col-6 p-3">
							<div class="text-cyan-900 font-medium mb-2">Email</div>
							<div class="text-900">{{agencyData.email}}</div>
						</div>
						<div class="col-12 md:col-6 p-3">
							<div class="text-cyan-900 font-medium mb-2">Contacts</div>
							<div class="text-900">
								<span v-if="agencyData.primary_contact">
									<span v-if="agencyData.primary_contact.toString().slice(0,3) == '880'">+</span><span v-else>+880</span>{{agencyData.primary_contact}}
								</span>,
								<span v-if="agencyData.secondary_contact">
									<span v-if="agencyData.secondary_contact.toString().slice(0,3) == '880'">+</span><span v-else>+880</span>{{agencyData.secondary_contact}}
								</span>
							</div>
						</div>
						<div class="col-12 md:col-6 p-3">
							<div class="text-cyan-900 font-medium mb-2">Registration No.</div>
							<div class="text-900">{{agencyData.registration_no}}</div>
						</div>
						<div class="col-12 md:col-6 p-3">
							<div class="text-cyan-900 font-medium mb-2">Created At</div>
							<div class="text-900">{{agencyData.created_at ? formatTime(agencyData.created_at) : '-'}}</div>
						</div>
						<div class="col-12 md:col-6 p-3">
							<div class="text-cyan-900 font-medium mb-2">Updated At</div>
							<div class="text-900">{{agencyData.updated_at ? formatTime(agencyData.updated_at) : '-'}}</div>
						</div>
						<div class="col-12 p-3">
							<div class="text-cyan-900 font-medium mb-2">Address</div>
							<div class="text-900 line-height-3">{{agencyData.address.line}}</div>
							<div class="text-900 line-height-3">{{agencyData.address.thana}}, {{agencyData.address.district}}, {{agencyData.address.division}}, {{agencyData.address.country}}.</div>
						</div>
						<div class="col-12 p-3">
							<div class="text-cyan-900 font-medium mb-2">Tour Policy</div>
							<div class="text-900 line-height-3" v-html="agencyData.tour_policy ? agencyData.tour_policy : '---'"></div>
						</div>
						<div class="col-12 p-3">
							<div class="text-cyan-900 font-medium mb-2">Children Policy</div>
							<div class="text-900 line-height-3" v-html="agencyData.cancellation_policy ? agencyData.cancellation_policy : '---'"></div>
						</div>
						<div class="col-12 p-3">
							<div class="text-cyan-900 font-medium mb-2">Cancellation Policy</div>
							<div class="text-900 line-height-3" v-html="agencyData.children_policy ? agencyData.children_policy : '---'"></div>
						</div>
					</div>
				</div>

				<template v-else>
					<div class="card">
						<div class="flex align-items-stretch justify-content-center flex-wrap card-container" style="min-height: 200px">
							<div class="flex align-items-center justify-content-center">
								<Message severity="info" :closable="false">No data found!</Message>
							</div>
						</div>
					</div>
				</template>

				<Dialog header="Crop Image" v-model:visible="displayImage" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '35vw'}" :modal="true">
					<div v-if="message">
						<Message severity="error">{{message}}</Message>
					</div>
					<cropper
						class="upload-example-cropper"
						:src="img.src"
						:stencil-props="{
							aspectRatio: 1/1
						}"
						ref="cropper"
					/>
					<template #footer>
					<Divider></Divider>
						<Button label="No" icon="pi pi-times" @click="closeImageCro" class="p-button-text"/>
						<Button label="Update" icon="pi pi-check" @click="crop" :loading="imageLoading" :disabled="message" />
					</template>
				</Dialog>

				<Dialog v-model:visible="agencyDialog" :style="{width: '550px'}" header="Update Basic" :modal="true" class="p-fluid" :closable="false">
					<div class="mb-2 -mt-1 "><small><span class="text-orange-600">Required field marked with</span> *</small></div>

					<div class="field">
						<label for="name">Name <span>*</span></label>
						<InputText
							id="name"
							v-model="valid$.name.$model"
							required="true"
							disabled
							placeholder="Enter Agency Name"
							:class="{'p-invalid': valid$.name.$invalid && submitted}"
						/>
						<span v-if="valid$.name.$error && submitted">
							<span
								id="name-error"
								v-for="(error, index) of valid$.name.$errors"
								:key="index"
							>
								<small class="p-error">{{error.$message.replace("Value", "Name")}}</small>
							</span>
						</span>
						<small
							v-else-if="(valid$.name.$invalid && submitted) || valid$.name.$pending.$response"
							class="p-error"
						>
							{{ valid$.name.required.$message.replace("Value", "Name") }}
						</small>
					</div>

					<div class="formgrid grid">
						<div class="field col">
							<label for="registration_no">Registration No <span>*</span></label>
							<InputText
								id="registration_no"
								v-model="valid$.registration_no.$model"
								:class="{'p-invalid': valid$.registration_no.$invalid && submitted}"
								placeholder="Enter Registration No"
								disabled
							/>
							<span v-if="valid$.registration_no.$error && submitted">
								<span
									id="registration_no-error"
									v-for="(error, index) of valid$.registration_no.$errors"
									:key="index"
								>
									<small class="p-error">{{error.$message.replace("Value", "Registration No")}}</small>
								</span>
							</span>
							<small
								v-else-if="(valid$.registration_no.$invalid && submitted) || valid$.registration_no.$pending.$response"
								class="p-error"
							>
								{{ valid$.registration_no.required.$message.replace("Value", "Registration No") }}
							</small>
						</div>

						<div class="field col">
							<label for="email">E-mail <span>*</span></label>
							<InputText
								:class="{'p-invalid': valid$.email.$invalid && submitted}"
								id="email"
								v-model="valid$.email.$model"
								type="email"
								placeholder="Enter E-mail"
							/>
							<span v-if="valid$.email.$error && submitted">
								<span
									id="email-error"
									v-for="(error, index) of valid$.email.$errors"
									:key="index"
								>
									<small class="p-error">{{error.$message.replace("Value", "E-mail")}}</small>
								</span>
							</span>
							<small
								v-else-if="(valid$.email.$invalid && submitted) || valid$.email.$pending.$response"
								class="p-error"
							>
								{{ valid$.email.required.$message.replace("Value", "E-mail") }}
							</small>
						</div>
					</div>
					
					<div class="formgrid grid">
						<div class="field col">
							<label for="primary_contact">Primary Contact <span>*</span></label>
							<InputText
								:class="{'p-invalid': valid$.primary_contact.$invalid && submitted}"
								id="primary_contact"
								v-model="valid$.primary_contact.$model"
								placeholder="Enter Primary Contact"
							/>
							<span v-if="valid$.primary_contact.$error && submitted">
								<span
									id="primary_contact-error"
									v-for="(error, index) of valid$.primary_contact.$errors"
									:key="index"
								>
									<small class="p-error">{{error.$message.replace("Value", "Primary Contact")}}</small>
								</span>
							</span>
							<small
								v-else-if="(valid$.primary_contact.$invalid && submitted) || valid$.primary_contact.$pending.$response"
								class="p-error"
							>
								{{ valid$.primary_contact.required.$message.replace("Value", "Primary Contact") }}
							</small>
						</div>

						<div class="field col">
							<label for="secondary_contact">Secondary Contact <span>*</span></label>
							<InputText
								:class="{'p-invalid': valid$.secondary_contact.$invalid && submitted}"
								id="secondary_contact"
								v-model="valid$.secondary_contact.$model"
								placeholder="Enter Secondary Contact"
							/>
							<span v-if="valid$.secondary_contact.$error && submitted">
								<span
									id="secondary_contact-error"
									v-for="(error, index) of valid$.secondary_contact.$errors"
									:key="index"
								>
									<small class="p-error">{{error.$message.replace("Value", "Secondary Contact")}}</small>
								</span>
							</span>
							<small
								v-else-if="(valid$.secondary_contact.$invalid && submitted) || valid$.secondary_contact.$pending.$response"
								class="p-error"
							>
								{{ valid$.secondary_contact.required.$message.replace("Value", "Secondary Contact") }}
							</small>
						</div>
					</div>

					<div class="field">
						<label for="tour_policy">Tour Policy</label>
						<Editor
							editorStyle="height: 85px"
							:class="{'p-invalid': valid$.tour_policy.$invalid && submitted}"
							id="tour_policy"
							v-model="valid$.tour_policy.$model"
							:autoResize="true"
							placeholder="Enter Tour Policy"
						>
							<template v-slot:toolbar>
								<span class="ql-formats">
									<select class="ql-font">
										<option selected="selected"></option>
										<option value="serif"></option>
										<option value="monospace"></option>
									</select>
									<select class="ql-size">
										<option value="small"></option>
										<!-- Note a missing, thus falsy value, is used to reset to default -->
										<option selected></option>
										<option value="large"></option>
										<option value="huge"></option>
									</select>
								</span>
								<span class="ql-formats">
									<button class="ql-bold"></button>
									<button class="ql-italic"></button>
									<button class="ql-underline"></button>
									<button class="ql-strike"></button>
								</span>
								<span class="ql-formats">
									<select class="ql-color">
											<option selected="selected"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option value="#ffffff"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option>
									</select>
									<select class="ql-background">
										<option value="#000000"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option selected="selected"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option>
									</select>
								</span>
								<span class="ql-formats">
									<button class="ql-script" value="sub"></button>
									<button class="ql-script" value="super"></button>
								</span>
								<span class="ql-formats">
									<button class="ql-header" value="1"></button>
									<button class="ql-header" value="2"></button>
									<button class="ql-blockquote"></button>
									<button class="ql-code-block"></button>
								</span>
								<span class="ql-formats">
									<button class="ql-list" value="ordered"></button>
									<button class="ql-list" value="bullet"></button>
									<button class="ql-indent" value="-1"></button>
									<button class="ql-indent" value="+1"></button>
								</span>
								<span class="ql-formats">
									<button class="ql-direction ql-active" value="rtl" type="button"></button>
									<select class="ql-align">
										<option selected="selected"></option><option value="center"></option><option value="right"></option><option value="justify"></option>
									</select>
								</span>
							</template>
						</Editor>
					</div>

					<div class="field">
						<label for="children_policy">Children Policy</label>
						<Editor
							editorStyle="height: 85px"
							:class="{'p-invalid': valid$.children_policy.$invalid && submitted}"
							id="children_policy"
							v-model="valid$.children_policy.$model"
							:autoResize="true"
							placeholder="Enter Children Policy"
						>
							<template v-slot:toolbar>
								<span class="ql-formats">
									<select class="ql-font">
										<option selected="selected"></option>
										<option value="serif"></option>
										<option value="monospace"></option>
									</select>
									<select class="ql-size">
										<option value="small"></option>
										<!-- Note a missing, thus falsy value, is used to reset to default -->
										<option selected></option>
										<option value="large"></option>
										<option value="huge"></option>
									</select>
								</span>
								<span class="ql-formats">
									<button class="ql-bold"></button>
									<button class="ql-italic"></button>
									<button class="ql-underline"></button>
									<button class="ql-strike"></button>
								</span>
								<span class="ql-formats">
									<select class="ql-color">
											<option selected="selected"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option value="#ffffff"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option>
									</select>
									<select class="ql-background">
										<option value="#000000"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option selected="selected"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option>
									</select>
								</span>
								<span class="ql-formats">
									<button class="ql-script" value="sub"></button>
									<button class="ql-script" value="super"></button>
								</span>
								<span class="ql-formats">
									<button class="ql-header" value="1"></button>
									<button class="ql-header" value="2"></button>
									<button class="ql-blockquote"></button>
									<button class="ql-code-block"></button>
								</span>
								<span class="ql-formats">
									<button class="ql-list" value="ordered"></button>
									<button class="ql-list" value="bullet"></button>
									<button class="ql-indent" value="-1"></button>
									<button class="ql-indent" value="+1"></button>
								</span>
								<span class="ql-formats">
									<button class="ql-direction ql-active" value="rtl" type="button"></button>
									<select class="ql-align">
										<option selected="selected"></option><option value="center"></option><option value="right"></option><option value="justify"></option>
									</select>
								</span>
							</template>
						</Editor>
					</div>

					<div class="field">
						<label for="cancellation_policy">Cancellation Policy</label>
						<Editor
							editorStyle="height: 85px"
							:class="{'p-invalid': valid$.cancellation_policy.$invalid && submitted}"
							id="cancellation_policy"
							v-model="valid$.cancellation_policy.$model"
							:autoResize="true"
							placeholder="Enter Cancellation Policy"
						>
							<template v-slot:toolbar>
								<span class="ql-formats">
									<select class="ql-font">
										<option selected="selected"></option>
										<option value="serif"></option>
										<option value="monospace"></option>
									</select>
									<select class="ql-size">
										<option value="small"></option>
										<!-- Note a missing, thus falsy value, is used to reset to default -->
										<option selected></option>
										<option value="large"></option>
										<option value="huge"></option>
									</select>
								</span>
								<span class="ql-formats">
									<button class="ql-bold"></button>
									<button class="ql-italic"></button>
									<button class="ql-underline"></button>
									<button class="ql-strike"></button>
								</span>
								<span class="ql-formats">
									<select class="ql-color">
											<option selected="selected"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option value="#ffffff"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option>
									</select>
									<select class="ql-background">
										<option value="#000000"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option selected="selected"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option>
									</select>
								</span>
								<span class="ql-formats">
									<button class="ql-script" value="sub"></button>
									<button class="ql-script" value="super"></button>
								</span>
								<span class="ql-formats">
									<button class="ql-header" value="1"></button>
									<button class="ql-header" value="2"></button>
									<button class="ql-blockquote"></button>
									<button class="ql-code-block"></button>
								</span>
								<span class="ql-formats">
									<button class="ql-list" value="ordered"></button>
									<button class="ql-list" value="bullet"></button>
									<button class="ql-indent" value="-1"></button>
									<button class="ql-indent" value="+1"></button>
								</span>
								<span class="ql-formats">
									<button class="ql-direction ql-active" value="rtl" type="button"></button>
									<select class="ql-align">
										<option selected="selected"></option><option value="center"></option><option value="right"></option><option value="justify"></option>
									</select>
								</span>
							</template>
						</Editor>
					</div>

					<template #footer>
						<Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Update" icon="pi pi-check" class="p-button p-button-raised" @click="basicEdit(!valid$.$invalid)" :loading="loading"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="addressDialog" :style="{width: '550px'}" header="Update Basic" :modal="true" class="p-fluid" :closable="false">
					<div class="mb-2 -mt-1 "><small><span class="text-orange-600">Required field marked with</span> *</small></div>

					<div class="formgrid grid">
						<div class="field col">
							<label for="country">Country <span>*</span></label>
							<InputText
								id="country"
								v-model="validAddress$.country.$model"
								disabled
								:class="{'p-invalid': validAddress$.country.$invalid && submitted}"
							/>
							<span v-if="validAddress$.country.$error && submitted">
								<span
									id="country-error"
									v-for="(error, index) of validAddress$.country.$errors"
									:key="index"
								>
									<small class="p-error">{{error.$message.replace("Value", "Country")}}</small>
								</span>
							</span>
							<small
								v-else-if="(validAddress$.country.$invalid && submitted) || validAddress$.country.$pending.$response"
								class="p-error"
							>
								{{ validAddress$.country.required.$message.replace("Value", "Country") }}
							</small>
						</div>
						<div class="field col">
							<label for="division">Division <span>*</span></label>
							<Dropdown
								id="division"
								v-model="validAddress$.division.$model"
								@change="getDistrict(validAddress$.division.$model)"
								:options="divisionList"
								:filter="true"
								optionLabel="value"
								optionValue="value"
								placeholder="Select your Division"
								:class="{'p-invalid': validAddress$.division.$invalid && submitted}"
							/>
							<span v-if="validAddress$.division.$error && submitted">
								<span
									id="division-error"
									v-for="(error, index) of validAddress$.division.$errors"
									:key="index"
								>
									<small class="p-error">{{error.$message.replace("Value", "Division")}}</small>
								</span>
							</span>
							<small
								v-else-if="(validAddress$.division.$invalid && submitted) || validAddress$.division.$pending.$response"
								class="p-error"
							>
								{{ validAddress$.division.required.$message.replace("Value", "Division") }}
							</small>
						</div>
					</div>

					<div class="formgrid grid">
						<div class="field col">
							<label for="district">District <span>*</span></label>
							<Dropdown
								id="district"
								v-model="validAddress$.district.$model"
								@change="getThana(validAddress$.district.$model)"
								:options="districtList"
								:filter="true"
								optionLabel="value"
								optionValue="value"
								placeholder="Select your District"
								:class="{'p-invalid': validAddress$.district.$invalid && submitted}"
							/>
							<span v-if="validAddress$.district.$error && submitted">
								<span
									id="district-error"
									v-for="(error, index) of validAddress$.district.$errors"
									:key="index"
								>
									<small class="p-error">{{error.$message.replace("Value", "District")}}</small>
								</span>
							</span>
							<small
								v-else-if="(validAddress$.district.$invalid && submitted) || validAddress$.district.$pending.$response"
								class="p-error"
							>
								{{ validAddress$.district.required.$message.replace("Value", "District") }}
							</small>
						</div>
						<div class="field col">
							<label for="thana">Thana <span>*</span></label>
							<Dropdown
								id="thana"
								v-model="validAddress$.thana.$model"
								:options="thanaList"
								:filter="true"
								optionLabel="value"
								optionValue="value"
								placeholder="Select your Thana"
								:class="{'p-invalid': validAddress$.thana.$invalid && submitted}"
							/>
							<span v-if="validAddress$.thana.$error && submitted">
								<span
									id="thana-error"
									v-for="(error, index) of validAddress$.thana.$errors"
									:key="index"
								>
									<small class="p-error">{{error.$message.replace("Value", "Thana")}}</small>
								</span>
							</span>
							<small
								v-else-if="(validAddress$.thana.$invalid && submitted) || validAddress$.thana.$pending.$response"
								class="p-error"
							>
								{{ validAddress$.thana.required.$message.replace("Value", "Thana") }}
							</small>
						</div>
					</div>

					<div class="field">
						<label for="line">Line</label>
						<Textarea
							id="line"
							v-model="validAddress$.line.$model"
							:autoResize="true"
							placeholder="Enter Line"
						/>
					</div>

					<template #footer>
						<Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Update" icon="pi pi-check" class="p-button p-button-raised" @click="addressEdit(!validAddress$.$invalid)" :loading="loading"/>
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import { reactive, ref } from '@vue/reactivity';
import Axios from 'axios';
import moment from 'moment';
import useVuelidate from '@vuelidate/core';
import { required, minLength, maxLength, email, numeric } from '@vuelidate/validators';
import { useToast } from 'primevue/usetoast';
import GlobalValues from '../data/GlobalValues';
import store from '../vuex';
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import Constant from '../data/constant';

export default {
	components: {
		Cropper,
	},
	setup() {
		const loading = ref(false);
		const pageLoading = ref(true);
		const agencyData = ref(null);
		const userList = ref(null);
		const constant =  ref(new Constant());

		const agencyDialog = ref(false);
		const addressDialog = ref(false);
		const closeDialog = ref(false);

		const selectedItem = ref(null);
		const submitted = ref(false);

		const divisionList = ref();
		const districtList = ref();
		const thanaList = ref();

		const toast = useToast();
		const globalValues = ref(new GlobalValues());

		const state = reactive({
			name : '',
			registration_no : '',
			primary_contact : '',
			secondary_contact : '',
			email : '',
			tour_policy : '',
			children_policy : '',
			cancellation_policy : '',
		});

		const stateAddress = reactive({
			line : '',
			thana : '',
			district : '',
			division : '',
			country : 'Bangladesh',
		});

		const rules = {
			name : {required, maxLength: maxLength(255), minLength: minLength(3)},
			registration_no : {required, maxLength: maxLength(255)},
			primary_contact : {required, numeric, maxLength: maxLength(255)},
			secondary_contact : {required, numeric, maxLength: maxLength(255)},
			email : {required, email},
			tour_policy : {},
			children_policy : {},
			cancellation_policy : {},
		};
		
		const rulesAddress = {
			line: {},
			thana : {required},
			district : {required},
			division : {required},
			country : {required},
		};

		const valid$ = useVuelidate (rules, state);
		const validAddress$ = useVuelidate (rulesAddress, stateAddress);

		const getagencyData = () =>{
			Axios
			.get("api/operator/agency")
			.then((res) =>{
				pageLoading.value = false;
				if (res.data.response == "success") {
					agencyData.value = res.data.data;
				}
				else{
					console.log(res.data.message);
				}
			})
			.catch((err) =>{
				console.log(err);
				pageLoading.value = false;
			})
		};
		getagencyData();

		//ADDRESSES
		const getDivision = () => {
            divisionList.value = globalValues.value.getDivisions(stateAddress.country);

            stateAddress.division = null;
            stateAddress.district = null;
            stateAddress.thana = null;
        }
        getDivision();
        const getDistrict = (division) => {
            districtList.value = globalValues.value.getDistricts(division);

            stateAddress.district = null;
            stateAddress.thana = null;
        };
        const getThana = (district) => {
            thanaList.value = globalValues.value.getThanas(district);
        };

		var userData = store.getters.userData;
        var user = JSON.parse(userData);

		const showToast = (severity, summary, detail) => {
			toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
		};

		const formatDate = (rawDate) => {
            return moment(rawDate).format("Do MMMM YYYY HH:MM A");
        };

		const formatTime = (rawDate) => {
            return moment(rawDate).format("Do MMMM YYYY hh:mm:ss a");
        };

		const img = ref();
		const image = ref();
		const displayImage = ref(false);
		const message = ref();
		const imageLoading = ref();
		const cropper = ref(null);

		const imageSelected = (event) => {
            img.value = event.target.files[0];
            img.value.src = URL.createObjectURL(event.target.files[0]);
            displayImage.value = true;

            if (img.value.size/1024 < 1024) {
                displayImage.value = true;
            }else{
                message.value = 'Maximum size of the picture can be 1 MB. This picture size is '+ (img.value.size/(1024*1024)).toFixed(2) +'MB';
            }
        };

		const closeImageCro = () => {
			displayImage.value = false;
		};

		const crop = () => {
            const { canvas } = cropper.value.getResult();
            imageLoading.value = true;

            if (canvas) {
				canvas.toBlob(blob => {
                    
					image.value = blob;


					const formData = new FormData();

					// if (image.value) {
						formData.append("logo", image.value);
					// }
					formData.append("_method", "PUT");

					Axios
					.post(`api/operator/agency/update-logo`, formData, {
						config: {
							headers: {'Content-Type': 'multipart/form-data' }
						}
					})
					.then((res) => {
						loading.value = false;
						if(res.data.response=="success") {
							showToast("success", "Success", res.data.message);
							getagencyData();
						}
						else {
							loading.value = false;
							showToast("error", "Error", res.data.message);
							console.log(res.data.message);
						}
					})
					.catch((err) => {
						loading.value = false;
						console.log(err);
						showToast("error", "Error", "Something went wrong. Try again!");
					});
					
                    imageLoading.value = false;
                    displayImage.value = false;

				});
			}
        };

		const hideDialog = () => {
			selectedItem.value = null;
			agencyDialog.value = false;
			addressDialog.value = false;
			submitted.value = false;

			state.name = '';
			state.registration_no = '';
			state.primary_contact = '';
			state.secondary_contact = '';
			state.email = '';
			state.tour_policy = '';
			state.children_policy = '';
			state.cancellation_policy = '';
			stateAddress.line = '';
			stateAddress.thana = '';
			stateAddress.district = '';
			stateAddress.division = '';
		};

		//EDIT OR UPDATE BASIC
		const editOpen = (product) => {
			selectedItem.value = product;
			agencyDialog.value = true;

			state.name = selectedItem.value.name;
			state.registration_no = selectedItem.value.registration_no;
			state.primary_contact = selectedItem.value.primary_contact;
			state.secondary_contact = selectedItem.value.secondary_contact;
			state.email = selectedItem.value.email;
			if(selectedItem.value.tour_policy != ('null' || null || ""))
			state.tour_policy = selectedItem.value.tour_policy;
			if(selectedItem.value.children_policy != ('null' || null || ""))
			state.children_policy = selectedItem.value.children_policy;
			if(selectedItem.value.cancellation_policy != ('null' || null))
			state.cancellation_policy = selectedItem.value.cancellation_policy;

			const getDivisionU = () => {
				divisionList.value = globalValues.value.getDivisions(stateAddress.country);
			}
			const getDistrictU = (division) => {
				districtList.value = globalValues.value.getDistricts(division);
			};
			const getThanaU = (district) => {
				thanaList.value = globalValues.value.getThanas(district);
			};

			getDivisionU();
			getDistrictU(selectedItem.value.address.division);
			getThanaU(selectedItem.value.address.district);
		};

		const basicEdit = (isFormValid) => {
			submitted.value = true;
            if (!isFormValid) {
                return;
            }
			loading.value = true;

			const formData1 = new FormData();

			// formData1.append("name", state.name);
			// formData1.append("registration_no", state.registration_no);
			formData1.append("primary_contact", state.primary_contact);
			formData1.append("secondary_contact", state.secondary_contact);
			formData1.append("email", state.email);
			if(state.tour_policy){
			formData1.append("tour_policy", state.tour_policy);}
			if(state.children_policy)
			formData1.append("children_policy", state.children_policy);
			if(state.cancellation_policy)
			formData1.append("cancellation_policy", state.cancellation_policy);

			formData1.append("_method", "PUT");

			Axios
            .post(`api/operator/agency/update-basics`, formData1, {
                config: {
                    headers: {'Content-Type': 'multipart/form-data' }
                }
            })
            .then( (res) => {
                loading.value = false;
                if(res.data.response=="success") {
                    showToast("success", "Success", res.data.message);
					selectedItem.value = null;
					agencyDialog.value = false;
					getagencyData();
                }
                else{
                    showToast("error", "Error", res.data.message);
                    console.log(res.data.message);
                }
            })
            .catch((err) => {
				loading.value = false;
                console.log(err);
                showToast("error", "Error", "Something went wrong. Try again!");
            });
		};

		//EDIT OR UPDATE ADDRESS
		const editAddress = (product) => {
			selectedItem.value = product;
			addressDialog.value = true;

			stateAddress.line = selectedItem.value.address.line;
			stateAddress.thana = selectedItem.value.address.thana;
			stateAddress.district = selectedItem.value.address.district;
			stateAddress.division = selectedItem.value.address.division;
			stateAddress.country = selectedItem.value.address.country;

			const getDivisionU = () => {
				divisionList.value = globalValues.value.getDivisions(stateAddress.country);
			}
			const getDistrictU = (division) => {
				districtList.value = globalValues.value.getDistricts(division);
			};
			const getThanaU = (district) => {
				thanaList.value = globalValues.value.getThanas(district);
			};

			getDivisionU();
			getDistrictU(selectedItem.value.address.division);
			getThanaU(selectedItem.value.address.district);
		};

		const addressEdit = (isFormValid) => {
			submitted.value = true;
            if (!isFormValid) {
				return;
            }
			loading.value = true;

			const formData1 = new FormData();

			formData1.append("line", stateAddress.line);
			formData1.append("thana", stateAddress.thana);
			formData1.append("district", stateAddress.district);
			formData1.append("division", stateAddress.division);
			formData1.append("country", stateAddress.country);

			formData1.append("_method", "PUT");

			Axios
            .post(`api/operator/agency/update-address`, formData1, {
                config: {
                    headers: {'Content-Type': 'multipart/form-data' }
                }
            })
            .then( (res) => {
                loading.value = false;
                if(res.data.response=="success") {
                    showToast("success", "Success", res.data.message);
					selectedItem.value = null;
					addressDialog.value = false;
					getagencyData();
                }
                else{
                    showToast("error", "Error", res.data.message);
                    console.log(res.data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                showToast("error", "Error", "Something went wrong. Try again!");
            });
		};

		return{
			pageLoading,
			loading,
			valid$,
			validAddress$,
			submitted,
			user,
			constant,

			agencyDialog,
			addressDialog,
			closeDialog,
			selectedItem,
			agencyData,
			userList,
			formatDate,
			formatTime,
			editOpen,
			editAddress,
			basicEdit,
			addressEdit,
			hideDialog,

			getDivision,
			getDistrict,
			getThana,
			divisionList,
			districtList,
			thanaList,

			imageSelected,
			displayImage,
			img,
			message,
			closeImageCro,
			imageLoading,
			crop,
			cropper
		}
	},
}
</script>

<style scoped lang="scss">
	@import '../assets/demo/badges.scss';
	.hov-button {
		display: none;
	}
	.content:hover .hov-button {
		display: block;
	}
</style>
